import CART_STATE from './'
import CartActions from './actions'

export function reducer(state = CART_STATE, action) {
  switch (action.type) {
    case CartActions.addArticle().type:
      return state.merge({
        articles: [...state.articles, action.payload.product] })
    default:
      return state
  }
}
