import React from 'react'

import Router from './Navigation/router'

const App = () => {
  return (
    <main role="application">
      <Router />
    </main>
  )
}

export default App
