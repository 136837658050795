import { createActions } from 'redux-actions'

import { getCategories } from '../../Services/categories'

const CategoriesActions = createActions({
  CATEGORIES_REQUEST: () => {},
  CATEGORIES_RESPONSE: response => ({ response }),
})

CategoriesActions.loadCategories = () => {
  return async dispatch => {

    try {

      dispatch( CategoriesActions.categoriesRequest() )

      const categoriesResponse = await getCategories()

      console.log('categoriesResponse: ', categoriesResponse)

      if (!categoriesResponse.ok)
        return dispatch( CategoriesActions.categoriesResponse( { error: categoriesResponse.data.error } ) )

      dispatch(
        CategoriesActions.categoriesResponse({ categories: categoriesResponse.data })
      )


    } catch (err) {
      dispatch( CategoriesActions.categoriesResponse({ error: err }) )
    }

  }
}

export default CategoriesActions
