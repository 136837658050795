import React from 'react'

import Header from '../../Components/Header'
import OrderStatus from '../../Components/OrderStatus'

import { useHistory } from 'react-router-dom'

import './index.css'

const Payment = () => {

  const history = useHistory()

  return (
    <div>
      <Header />
      <OrderStatus status="Método de Pago" />

      <section className="contenedor-datos-resumen-pedido">
        <div className="contenedor-datos">
          <div className="titulo-datos">
            <h3>Método de Pago</h3>
          </div>
          <div className="campo-datos">
            <div className="titulo-tarjetas">
              <h2>TARJETA DEBITO / CREDITO</h2>
            </div>
            <div className="tarjeta">
              <input type="text" className="tarjeta-datos" name="Tarjeta" placeholder="Número Tarjeta"/>
            </div>
            <div className="titular">
              <input type="text" className="titular-datos" name="Titular" placeholder="Titular Tarjeta"/>
            </div>

            <div className="caducidad-campo">
              <p>Fecha de Caducidad</p>
              <input type="text" className="mes-datos" name="Caducidad-mes" placeholder="Mes"/>
              <input type="text" className="ano-datos" name="Caducidad-ano" placeholder="Año"/>
            </div>
            <div className="cvv">
              <input type="text" className="cvv-datos" name="CVV" placeholder="CVV"/>
            </div>
            <div className="cuotas-campo">
              <p>Número de Cuotas</p>
              <input type="text" className="cuotas-datos" name="numero-cuotas"/>
            </div>
            <div className="linea2">
            </div>
            <div className="campo-pse">
              <div className="titulo-pse">
                <h2>PSE</h2>
                <a href="https://www.pse.com.co" target="_blank" rel="noreferrer">
                  <img src="images/pse-logo.png" alt="" />
                </a>
              </div>
              <p>
                <a href="https://www.pse.com.co" target="_blank" rel="noreferrer">
                  Serás redirigido al sitio seguro de PSE para que puedas realizar tu pago.
                </a>
              </p>
            </div>
            <div className="linea3">
            </div>
            <div className="campo-efecty">
              <div className="titulo-efecty">
                <h2>EFECTY</h2>
                <img src="images/efecty-logo.png" alt="" />
              </div>
              <p>
                <a href="https://www.efecty.com.co" target="_blank" rel="noreferrer">
                  Recuerda que el límite de pago por este medio es de 2 dias.
                </a>
              </p>
            </div>
            <div className="linea4">
            </div>
            <div className="campo-baloto">
              <div className="titulo-baloto">
                <h2>BALOTO</h2>
                <img src="images/baloto-logo.png" alt="" />
              </div>
              <p>
                <a href="https://www.via.com.co/pagos" target="_blank" rel="noreferrer">
                  Recuerda que el límite de pago por este medio es de 2 dias.
                </a>
              </p>
            </div>
          </div>
          <div onClick={ () => history.push('/compra') } className="boton3">
            Finalizar Pago
          </div>
        </div>
        <div className="contenedor-resumen-pedido">
          <div className="resumen-titulo">
            <h2>Resumen del Pedido</h2>
          </div>
          <div className="listado-productos">
            <div className="titulo-productos">
              <h3>Productos</h3>
            </div>
            <div className="cantidad-productos">
              <h4>Nombre del Producto</h4>
              <div className="precio-producto">
                <h4>$1.488.000</h4>
              </div>
            </div>
            <div className="linea">
            </div>
            <div className="subtotal">
              <h4>Subtotal</h4>
              <div className="precio-subtotal">
                <h4>$1.488.000</h4>
              </div>
            </div>
            <div className="descuento">
              <h4>Descuento</h4>
              <div className="precio-descuento">
                <h4>$0</h4>
              </div>
            </div>
            <div className="linea">
            </div>
            <div className="total-producto">
              <h4>Total de los productos</h4>
              <div className="precio-producto">
                <h4>$1.488.000</h4>
              </div>
            </div>
            <div className="linea">
            </div>
            <div className="gastos-envio">
              <h4>Gastos de Envío</h4>
              <div className="precio-envio">
                <h4>$20.000</h4>
              </div>
            </div>
            <div className="linea">
            </div>
            <div className="total">
              <h3>Total</h3>
              <div className="precio-total">
                <h3>$1.508.000</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Payment
