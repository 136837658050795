import api from './'

const key = 'access_token'

export const setAuthorizationToken = token => {
  if (!token) return
  localStorage.setItem(key, token)
  api.setHeader('Authorization', 'Bearer ' + token)
}

export const removeAuthorizationToken = () => {
  delete api.headers['Authorization']
  localStorage.removeItem(key)
}

export const currentCredentials = async () => {
  return localStorage.getItem(key)
}

export const restoreAuthorizationToken = async () => {
  const accessToken = currentCredentials()
  api.setHeader('Authorization', 'Bearer ' + accessToken)
}
