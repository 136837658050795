import React from 'react'

import { NavLink } from 'react-router-dom'

import './index.css'
import clsx from 'clsx'

const Menu = ({ selected, categories = [] }) => {
  return (
    <section className="contenedor-menu">

      <div className="secciones">

        <div className={ clsx("menu-item", "categorias")}>
          Categorías

          <div className="menu_categorias">

            { categories.map( (category, index) => (
              <div
                key={`category-menu-${index}`}
                className="menu_item_categorias">
                { category.name }
              </div>
            ) )}
            
          </div>

        </div>

        <div className={ clsx("menu-item", "descuentos", selected === "descuentos" ? "menu-item-seleccionado" : null)}>
          <NavLink to="/descuentos">
            Descuentos
          </NavLink>
          <img src="images/porcentaje-logo-negro.png" alt="" />
        </div>
        <div className={ clsx("menu-item", "productos-del-mes", selected === "productos-del-mes" ? "menu-item-seleccionado" : null) }>
          <NavLink to="/productos-del-mes">
            Productos del Mes
          </NavLink>
        </div>
      </div>
    </section>
  )
}

export default Menu
