import React from 'react'

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom'

import Index from '../Containers/Main'

import Login from '../Containers/Account/Login'
import Signup from '../Containers/Account/Signup'

import Discounts from '../Containers/Discounts'
import MonthProducts from '../Containers/MonthProducts'

import Cart from '../Containers/Cart'
import Shipping from '../Containers/Shipping'
import Payment from '../Containers/Payment'
import Purchase from '../Containers/Purchase'

const Navigation = () => {
  return (
    <Router>
      <Switch>
        <Route
          component={Login}
          path="/ingresar" />

        <Route
          component={Signup}
          path="/registrarse" />

        <Route
          component={Discounts}
          path="/descuentos" />

        <Route
          component={MonthProducts}
          path="/productos-del-mes" />

        <Route
          component={Cart}
          path="/carritto" />

        <Route
          component={Shipping}
          path="/envio" />

        <Route
          component={Payment}
          path="/pago" />

        <Route
          component={Purchase}
          path="/compra" />

        <Route
          component={Index}
          path="/" />

      </Switch>
    </Router>
  )
}

export default Navigation
