import React from 'react'

import { NavLink } from 'react-router-dom'

import './index.css'

const AccountHeader = ({ title }) => {
  return (
    <header className="account">
      <NavLink to="/">
        <img
          className="icono-home"
          src="images/logo-itto-negro.png"
          alt="" />
      </NavLink>
      <div className="lema-logo">
        <p>{ title }</p>
      </div>
    </header>
  )
}

export default AccountHeader
