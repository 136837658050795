import PRODUCTS_STATE from './'
import ProductsActions from './actions'

export function reducer(state = PRODUCTS_STATE, action) {

  switch (action.type) {

    case ProductsActions.monthProductsRequest().type:
      return state.merge({
        monthProductsLoading: true,
        monthProductsError: null,
        month: [] })
    case ProductsActions.monthProductsResponse().type:
      return state.merge({
        monthProductsLoading: false,
        monthProductsError: action.payload.response.error,
        month: action.payload.response.products || [],
      })

    default:
      return state
  }
}
