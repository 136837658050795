import AUTH_STATE from './'
import AuthActions from './actions'

export function reducer(state = AUTH_STATE, action) {

  switch (action.type) {
    case AuthActions.setUser().type:
      return state.merge({ user: action.payload.user })

    case AuthActions.noAuth().type:
      return state.merge({
        user: null,
        loading: false,
      })
    case AuthActions.verifyAuth().type:
      return state.merge({
        loading: true,
       })

    case AuthActions.restoredAuth().type:
      return state.merge({
        loading: false,
      })
    default:
      return state
  }
}
