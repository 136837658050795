import CATEGORIES_STATE from './'
import CategoriesActions from './actions'

export function reducer(state = CATEGORIES_STATE, action) {

  switch (action.type) {

    case CategoriesActions.categoriesRequest().type:
      return state.merge({
        categoriesLoading: true,
        categoriesError: null,
        categories: [] })
    case CategoriesActions.categoriesResponse().type:
      return state.merge({
        categoriesLoading: false,
        categoriesError: action.payload.response.error,
        categories: action.payload.response.categories,
      })

    default:
      return state
  }
}
