import React from 'react'

import Header from '../../Components/Header'
import OrderStatus from '../../Components/OrderStatus'


import { useHistory } from 'react-router-dom'

import './index.css'

const Shipping = () => {

  const history = useHistory()

  return (
    <div>
      <Header />
      <OrderStatus status="Datos para la Entrega" />

      <div className="linea-separador">
      </div>
      <section className="contenedor-datos-resumen-pedido">
        <div className="contenedor-datos">
          <div className="titulo-datos">
            <h3>Datos Entrega</h3>
          </div>
          <div className="campo-datos">
            <div className="nombre-apellido">
              <input type="text" className="nombre-datos" name="Nombre" placeholder="Nombre"/>
              <input type="text" className="apellido-datos" name="Apellidos" placeholder="Apellidos"/>
            </div>
            <div className="direccion">
              <input type="text" className="direccion-datos" name="Direccion" placeholder="Dirección"/>
            </div>
            <div className="edificio">
              <input type="text" className="edificio-datos" name="Direccion" placeholder="Conjunto, Edificio, Torre, Apartamento, Casa (Número)"/>
            </div>
            <div className="departamento">
              <input type="text" className="departamento-datos" name="Departamento" placeholder="Departamento"/>
            </div>
            <div className="ciudad">
              <input type="text" className="ciudad-datos" name="Ciudad" placeholder="Ciudad"/>
            </div>
            <div className="codigo">
              <input type="text" className="codigo-datos" name="Codigo" placeholder="Código Postal"/>
            </div>
            <div className="telefono">
              <input type="text" className="telefono-datos" name="Telefono" placeholder="Teléfono"/>
              <p>En el caso de tener alguna pregunta sobre el pedido.</p>
            </div>
            <div className="titulo-datos-facturacion">
              <h3>Datos para Facturación</h3>
            </div>
            <div className="correo">
              <input type="email" className="correo-datos" name="Correo" placeholder="Correo Electrónico"/>
            </div>
            <div className="documento">
              <input type="text" className="documento-datos" name="Documento" placeholder="Documento"/>
            </div>
            <div className="documento-numero">
              <input type="text" className="documento-numero-datos" name="Documento-Numero" placeholder="Documento Número"/>
            </div>
            <div className="check">
              <label>
                <input className="first_checkbox" type="checkbox" id="cbox1" value="first_checkbox"/>
                Apruebo a itto colombia con el fin de hacer uso de mis datos personales con
                fines comerciales como el envío de descuentos a nombre personal o el
                historial de compras.
              </label>
              <br/>
            </div>
          </div>
          <div onClick={ () => history.push("/pago") } className="boton3">
            Continuar
          </div>
        </div>
        <div className="contenedor-resumen-pedido">
          <div className="resumen-titulo">
            <h2>Resumen del Pedido</h2>
          </div>
          <div className="listado-productos">
            <div className="titulo-productos">
              <h3>Productos</h3>
            </div>
            <div className="cantidad-productos">
              <h4>Nombre del Producto</h4>
              <div className="precio-producto">
                <h4>$1.488.000</h4>
              </div>
            </div>
            <div className="linea">
            </div>
            <div className="subtotal">
              <h4>Subtotal</h4>
              <div className="precio-subtotal">
                <h4>$1.488.000</h4>
              </div>
            </div>
            <div className="descuento">
              <h4>Descuento</h4>
              <div className="precio-descuento">
                <h4>$0</h4>
              </div>
            </div>
            <div className="linea">
            </div>
            <div className="total-producto">
              <h4>Total de los productos</h4>
              <div className="precio-producto">
                <h4>$1.488.000</h4>
              </div>
            </div>
            <div className="linea">
            </div>
            <div className="gastos-envio">
              <h4>Gastos de Envío</h4>
              <div className="precio-envio">
                <h4>$20.000</h4>
              </div>
            </div>
            <div className="linea">
            </div>
            <div className="total">
              <h3>Total</h3>
              <div className="precio-total">
                <h3>$1.508.000</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Shipping
