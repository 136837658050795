import React from 'react'

import './index.css'

const Product = ({ id, name, price, image, onSelect }) => {
  return (
    <div
      key={id}
      className="producto"
      onClick={onSelect}
      >
      <a href="#producto">
        <img src={image} alt="" />
        <h5>{name}</h5>
        <h4>{price}</h4>
      </a>
    </div>
  )
}

export default Product
