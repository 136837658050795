import React from 'react'

import Header from '../../Components/Header'
import SearchBar from '../../Components/SearchBar'
import Menu from '../../Components/Menu'
import Newsletter from '../../Components/Newsletter'
import Footer from '../../Components/Footer'


const Discounts = () => {
  return (
    <div>

      <Header />
      <SearchBar />
      <Menu selected="descuentos" />
      <Newsletter />
      <Footer />
    </div>
  )
}

export default Discounts
