import React, { Fragment} from 'react'

import AuthActions from '../../Redux/account/actions'

import { NavLink } from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux'

import './index.css'

const Header = () => {

  const dispatch = useDispatch()
  const user = useSelector(state => state.auth.user)

  return (
    <header className="main">
      <NavLink to="/">
        <img
          className="icono-home"
          src="images/logo-itto-blanco.png"
          alt="" />
      </NavLink>
      <div className="lema-logo">
        <p>Marcando un Antes y un Despúes.</p>
      </div>
      <nav>
        <section>
          <img
            className="icono-registrar"
            src="images/register.png"
            alt="" />
        </section>
        { user ? (
          <div className="user-header">
            { user?.name || user?.email } |
            <span
              className="logout"
              onClick={ () => dispatch( AuthActions.logout() ) }>
              Salir
            </span>
          </div>
        ) : (
          <Fragment>
            <NavLink to="/registrarse">Registrarse</NavLink>
            <div className="rayita" />
            <NavLink to="/ingresar">Ingresar</NavLink>
          </Fragment>
        )}
      </nav>
    </header>
  )
}

export default Header
