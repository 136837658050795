import api from './'

export const login = (email, password) => {
  return api.post('auth/login', { email, password } )
}

export const signup = (email, password) => {
  return api.post('auth/signup', { email, password, role: 'client' } )
}

export const me = () => {
  return api.get('auth/me')
}

export function forgotPassword(email) {
  return api.post('/auth/forgot-password', { email })
}

export function changePassword(oldPassword, newPassword) {
  return api.patch('/auth/change-password', { oldPassword, newPassword })
}

export function updateProfile(params) {
  return api.put('/auth/me', params)
}
