import Immutable from 'seamless-immutable'

const INITIAL_STATE = Immutable({
  categoriesLoading: false,
  categoriesError: null,
  categories: [],

})

export default INITIAL_STATE
