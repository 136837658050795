import { combineReducers } from 'redux'

const rootReducer = combineReducers({
  account: require('./account/reducer').reducer,
  auth: require('./auth/reducer').reducer,
  categories: require('./categories/reducer').reducer,
  products: require('./products/reducer').reducer,
  cart: require('./cart/reducer').reducer,
})

export default rootReducer
