import React, { useEffect } from 'react'

import Header from '../../Components/Header'
import SearchBar from '../../Components/SearchBar'
import Menu from '../../Components/Menu'
import Product from '../../Components/Product'
import Newsletter from '../../Components/Newsletter'
import Footer from '../../Components/Footer'

import { NavLink } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import CategoriesActions from '../../Redux/categories/actions'

import './index.css'

const products = [
  {
    "name": "Play",
    "price": "$23000000",
    "image": "images/slide-1.png"
  },
  {
    "name": "Play 1",
    "price": "$23000000",
    "image": "images/slide-1.png"
  },
  {
    "name": "Play 2",
    "price": "$23000000",
    "image": "images/slide-1.png"
  },
  {
    "name": "Play 3",
    "price": "$23000000",
    "image": "images/slide-1.png"
  },
  {
    "name": "Play",
    "price": "$23000000",
    "image": "images/slide-1.png"
  },
  {
    "name": "Play 1",
    "price": "$23000000",
    "image": "images/slide-1.png"
  },
  {
    "name": "Play 2",
    "price": "$23000000",
    "image": "images/slide-1.png"
  },
  {
    "name": "Play 3",
    "price": "$23000000",
    "image": "images/slide-1.png"
  }

]

const Index = () => {

  const dispatch = useDispatch()
  const categories = useSelector( state => state.categories.categories )
  // const products = useSelector( state => state.products.all )

  useEffect( () => {
    dispatch( CategoriesActions.loadCategories() )
  }, [dispatch])

  return (
    <div>
      <Header />
      <SearchBar />
      <Menu categories={categories} />

      <section className="contenedor-slider">

          <div className="images-slide1">
            <img src="images/slide-1.png" alt="slide1" />
          </div>

          <div className="contenedor-info-slide1">
            <div className="texto-slide1">
              <img className="logo" src="images/porcentaje-logo-negro@3x.png" alt="" />
              <h4>Combo PS4</h4>
              <h1>$1.490.000</h1>
            </div>

            <div className="boton">
              <NavLink to="/carritto">
                Comprar Ahora
              </NavLink>
            </div>
          </div>

      </section>

      {/*<!-- <div className="menu-slide">
        <div>
          <a href="#slide1">1</a>
        </div>
      </div> -->*/}

      <section className="contenedor-promos">
        <a href="#promociones">
          <div className="promo1">
            <img src="images/slide-1.png" alt="" />
          </div>
        </a>
        <a href="#promociones">
          <div className="promo1">
            <img src="images/slide-1.png" alt="" />
          </div>
        </a>
        <a href="#promociones">
          <div className="promo1">
            <img src="images/slide-1.png" alt="" />
          </div>
        </a>
        <a href="#promociones">
          <div className="promo1">
            <img src="images/slide-1.png" alt="" />
          </div>
        </a>
      </section>

      <section className="contenedor-general-descuento-semanal-mas-Vendidos">
        <section className="contenedor-descuento-semanal">
          <div className="descuento-semanal">
            <h3>Descuento de la Semana</h3>
          </div>
          <div className="contenedor-descuento-semanal-info">
            <img src="images/slide-1.png" alt="" />
            <img className="logo" src="images/porcentaje-logo-negro@2x.png" alt="" />
            <h5>Combo PS4</h5>
            <h6>$1.990.000</h6>
            <h4>$1.488.000</h4>
            <div className="boton2">
              <a href="#comprar">Comprar Ahora</a>
            </div>
          </div>
        </section>
        <section className="contenedor-mas-vendidos">
          <div className="texto-mas-vendidos">
            <h3>Más Vendidos</h3>
          </div>
          <section className="mas-vendidos">
            { products.map( (product, index) => (
              <Product
                key={`home-product-${index}`}
                name={product.name}
                price={product.price}
                image={product.image} />
            ))}
          </section>
        </section>
      </section>
      <section className="contenedor-promos-2">
        <a href="#promociones-2">
          <div className="promo-2">
            <img src="images/slide-1.png" alt="" />
          </div>
        </a>
        <a href="#promociones-2">
          <div className="promo-2">
            <img src="images/slide-1.png" alt="" />
          </div>
        </a>
        <a href="#promociones-2">
          <div className="promo-2">
            <img src="images/slide-1.png" alt="" />
          </div>
        </a>
      </section>
      <section className="contenedor-slider-2">

          <div className="images-slide-2">
            <img src="images/slide-1.png" alt="slide1" />
          </div>

          <div className="contenedor-info-slide-2">
            <div className="texto-slide-2">
              <img className="logo" src="images/porcentaje-logo-negro@3x.png" alt="" />
              <h4>Combo PS4</h4>
              <h1>$1.490.000</h1>
            </div>

            <div className="boton">
                <a href="#carrito-itto">Comprar Ahora</a>
            </div>
          </div>

      </section>
      <section className="contenedor-promos-3">
        <a href="#promociones-3">
          <div className="promo-3">
            <img src="images/slide-1.png" alt="" />
          </div>
        </a>
        <a href="#promociones-3">
          <div className="promo-3">
            <img src="images/slide-1.png" alt="" />
          </div>
        </a>
        <a href="#promociones-3">
          <div className="promo-3">
            <img src="images/slide-1.png" alt="" />
          </div>
        </a>
      </section>

      <Newsletter />
      <Footer />
    </div>
  )
}

export default Index
