import React, { Fragment } from 'react'

import './index.css'

const OrderStatus = ({ status }) => {
  return (
    <Fragment>
      <section className="carrito-estado">
        <div className="carrito-logo3x">
          <img src="images/carrito-compras-logo@3x.png" alt="" />
        </div>
        <div className="carrito-estado-texto">
          <h3>Carrito itto</h3>
          <h4>Resumen del Pedido</h4>
          <h5>Estado: {status}</h5>
        </div>
      </section>
      <div className="linea-separador" />
    </Fragment>
  )
}

export default OrderStatus
