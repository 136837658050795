import { createActions } from 'redux-actions'

import {
  login as loginService,
  signup as signupService,
  me as meService,
  forgotPassword as forgotPasswordService,
  changePassword as changePasswordService,
  updateProfile as editProfileService,
} from '../../Services/account'

import CustomError from '../../Helpers/error'

import AuthActions from '../auth/actions'

const actions = createActions({
  LOGIN_REQUEST: () => {},
  LOGIN_SUCCESS: () => {},
  LOGIN_FAILURE: error => ({ error }),

  SIGNUP_REQUEST: () => {},
  SIGNUP_SUCCESS: () => {},
  SIGNUP_FAILURE: error => ({ error }),

  FORGOT_PASSWORD_REQUEST: () => {},
  FORGOT_PASSWORD_SUCCESS: () => {},
  FORGOT_PASSWORD_FAILURE: error => ({ error }),
  FORGOT_PASSWORD_RESET: () => {},

  CHANGE_PASSWORD_REQUEST: () => {},
  CHANGE_PASSWORD_SUCCESS: () => {},
  CHANGE_PASSWORD_FAILURE: error => ({ error }),
  CHANGE_PASSWORD_RESET: () => {},

  EDIT_PROFILE_REQUEST: () => {},
  EDIT_PROFILE_SUCCESS: () => {},
  EDIT_PROFILE_FAILURE: error => ({ error }),
  EDIT_PROFILE_RESET: () => {},
})

export const AccountActions = actions

AccountActions.login = (email, password) => {
  return async dispatch => {
    try {
      dispatch( AccountActions.loginRequest() )
      const result = await loginService(email, password)
      console.log('Result login: ', result)
      if (result.ok) {
        await dispatch( AuthActions.setAccessToken(result.data.access_token) )
        const resultMe = await meService()
        console.log('ResultMe: ', resultMe)
        if (!resultMe.ok) {
          return AccountActions.loginFailure(CustomError('Login -> me error'))
        }

        await dispatch( AuthActions.setUser(resultMe.data.data) )

        dispatch( AccountActions.loginSuccess() )
      } else if (result.data) {
        dispatch( AccountActions.loginFailure(result.data.error) )
      } else {
        dispatch( AccountActions.loginFailure(CustomError(result.problem)) )
      }

    } catch (err) {
      console.log('error on login service ', err)
      dispatch( AccountActions.loginFailure(err) )
    }
  }
}

AccountActions.signup = (email, password) => {
  return async dispatch => {

    try {
      dispatch( AccountActions.signupRequest() )
      const result = await signupService(email, password)
      if (result.ok) {
        await dispatch( AuthActions.setAccessToken(result.data.access_token))
        const resultMe = await meService()
        await dispatch( AuthActions.setUser(resultMe.data.data) )
        dispatch( AccountActions.signupSuccess() )
      } else if (result.data) {
        dispatch( AccountActions.signupFailure(result.data.error) )
      } else {
        dispatch( AccountActions.signupFailure({ debugMessage: result.problem }) )
      }
    } catch (err) {
      console.log('error on signup service: ', err)
    }
  }
}

AccountActions.forgotPassword = email => {
  return dispatch => {
    dispatch( AccountActions.forgotPasswordRequest() )
    forgotPasswordService(email)
      .then( result => {
        if ( result.ok ) {
          dispatch( AccountActions.forgotPasswordSuccess() )
        } else if (result.data) {
          dispatch( AccountActions.forgotPasswordFailure(result.data.error) )
        } else {
          dispatch( AccountActions.forgotPasswordFailure({ debugMessage: result.problem }) )
        }
      })
      .catch( err => {
        dispatch( AccountActions.forgotPasswordFailure({ debugMessage: err } ) )
      })
  }
}

AccountActions.changePassword = (oldPassword, newPassword) => {
  return dispatch => {

    dispatch( AccountActions.changePasswordRequest() )
    changePasswordService(oldPassword, newPassword)
      .then( result => {
        if ( result.ok ) {
          dispatch( AccountActions.changePasswordSuccess() )
        } else if (result.data) {
          dispatch( AccountActions.changePasswordFailure(result.data.error) )
        } else {
          dispatch( AccountActions.changePasswordFailure({ debugMessage: result.problem }) )
        }
      })
      .catch( err => {
        dispatch( AccountActions.changePasswordFailure({ debugMessage: err } ) )
      })
  }
}

AccountActions.updateProfile = params => {
  return async dispatch => {
    try {
      dispatch( AccountActions.editProfileRequest() )
      const result = await editProfileService({ basics: params })
      console.log('Edit profile result: ', result)
      if ( result.ok ) {
        await dispatch( AuthActions.setUser(result.data.data) )
        dispatch( AccountActions.editProfileSuccess() )
      } else if (result.data) {
        dispatch( AccountActions.editProfileFailure(result.data.error) )
      } else {
        dispatch( AccountActions.editProfileFailure({ debugMessage: result.problem }) )
      }
    } catch (err) {
      dispatch( AccountActions.editProfileFailure({ debugMessage: err } ) )
    }
  }
}

AccountActions.logout = () => {
  return dispatch => {
    dispatch( AuthActions.removeAuth() )
  }
}

export default AccountActions
