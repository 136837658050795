import React from 'react'

import Header from '../../Components/Header'
import OrderStatus from '../../Components/OrderStatus'

import './index.css'

const Purchase = () => {
  return  (
    <div>
      <Header />
      <OrderStatus status="Compra Exitosa" />
      <section className="contenedor-datos-resumen-pedido">
        <div className="contenedor-datos">
          <div className="titulo-datos">
            <h3>Compra Exitosa</h3>
          </div>
          <div className="campo-datos">
            <div className="parrafo-datos">
              <p>Tu compra se ha realizado exitosamente, tu número de orden es <b>IT-TE-12345678 </b>
                y recibirás un correo de confirmación junto con todos los datos e información de
                tu orden.</p>
            </div>
            <div className="parrafo-datos2">
              <p>Recuerda que una vez recibido y confirmado tu pago el pedido será notificado
                a nuestro centro de distribución para validar que los items escogidos se encuentren
                disponibles y así ser enviado a la dirección correspondiente.</p>
            </div>
            <div className="linea2">
            </div>
            <div className="campo-orden">
              <div className="titulo-orden">
                <h2>Número de Orden</h2>
              </div>
              <b>IT-TE-12345678</b>
            </div>
            <div className="linea3">
            </div>
            <div className="campo-envio">
              <div className="titulo-envio">
                <h2>Dirección de Envío</h2>
              </div>
              <p>Nombre:</p>
              <p>Dirección:</p>
              <p>Teléfono:</p>
              <p>Email:</p>
            </div>
            <div className="linea4">
            </div>
            <div className="campo-gracias">
              <div className="titulo-gracias">
                <h2>¡GRACIAS POR TU COMPRA!</h2>
                <img src="images/itto-logo-negro-letra.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="contenedor-resumen-pedido">
          <div className="resumen-titulo">
            <h2>Resumen del Pedido</h2>
          </div>
          <div className="listado-productos">
            <div className="titulo-productos">
              <h3>Productos</h3>
            </div>
            <div className="cantidad-productos">
              <h4>Nombre del Producto</h4>
              <div className="precio-producto">
                <h4>$1.488.000</h4>
              </div>
            </div>
            <div className="linea">
            </div>
            <div className="subtotal">
              <h4>Subtotal</h4>
              <div className="precio-subtotal">
                <h4>$1.488.000</h4>
              </div>
            </div>
            <div className="descuento">
              <h4>Descuento</h4>
              <div className="precio-descuento">
                <h4>$0</h4>
              </div>
            </div>
            <div className="linea">
            </div>
            <div className="total-producto">
              <h4>Total de los productos</h4>
              <div className="precio-producto">
                <h4>$1.488.000</h4>
              </div>
            </div>
            <div className="linea">
            </div>
            <div className="gastos-envio">
              <h4>Gastos de Envío</h4>
              <div className="precio-envio">
                <h4>$20.000</h4>
              </div>
            </div>
            <div className="linea">
            </div>
            <div className="total">
              <h3>Total</h3>
              <div className="precio-total">
                <h3>$1.508.000</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Purchase
