import React, { useEffect, useRef } from 'react'

import AccountHeader from '../../../Components/Header/Account'

import AccountActions from '../../../Redux/account/actions'

import { Formik } from 'formik'
import * as Yup from 'yup'

import { useDispatch, useSelector } from 'react-redux'

import './index.css'

const LoginValidationSchema = Yup.object({
       email: Yup.string()
              .email('Email inválido')
              .required('*'),
       password: Yup.string()
                  .required('*'),
       confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden')
     })

const Login = ({ history }) => {

  const dispatch = useDispatch()
  const {
    signedUp,
    signUpLoading
  } = useSelector( state => state.account )

  const formRef = useRef(null)

  useEffect(() => {
    formRef.current.setSubmitting(signUpLoading)
  }, [formRef, signUpLoading])

  useEffect(() => {
    if (signedUp) {
      history.replace('/')
    }
  }, [history, signedUp])

  const onSubmit = (values) => {
    dispatch( AccountActions.signup(values.email, values.password) )
  }

  return (
    <div>
      <AccountHeader title="Regístrate"/>

      <section className="contenedor-register">

        <section className="register-logo">
          <img src="images/register-2.png" alt="" />
          <h4>Regístrate</h4>
        </section>

        <Formik
          innerRef={formRef}
          onSubmit={onSubmit}
          validationSchema={LoginValidationSchema}
          initialValues={{ email: '', password: '', confirmPassword: '' }}>
          { ({
            values,
            handleSubmit,
            errors,
            touched,
            handleChange,
            isSubmitting,
            handleBlur,
          }) => (
            <form onSubmit={handleSubmit}>
              <section className="contenedor-campo-correo">
                <div className="email">
                  <input
                    placeholder="Email"
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email} />
                </div>
                { touched.email && errors.email ? (
                  <div className="error">
                    {errors.email}
                  </div>
                ) : null }
                <div className="Contrasena">
                  <input
                    placeholder="Contraseña"
                    type="password"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password} />
                </div>
                { touched.password && errors.password ? (
                  <div className="error">
                    {errors.password}
                  </div>
                ) : null }
                <div className="Confirmar-contrasena">
                  <input
                    placeholder="Confirmar Contraseña"
                    type="password"
                    name="confirmPassword"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmPassword} />
                </div>
                { touched.confirmPassword && errors.confirmPassword ? (
                  <div className="error">
                    {errors.confirmPassword}
                  </div>
                ) : null }
              </section>
              <section className="continuar-boton">
                <button type="submit" disabled={isSubmitting}>
                  { isSubmitting ? "Cargando" : "Continuar" }
                </button>
              </section>
            </form>
          )}
        </Formik>

        <div className="registrate-con">
          <h4>Regístrate Con</h4>
        </div>
        <section className="contenedor-redes-ingreso">
          <div className="facebook">
            <img src="images/facebook.png" alt="" />
          </div>
          <div className="google">
            <img src="images/google.png" alt="" />
          </div>
          <div className="twitter">
            <img src="images/twitter.png" alt="" />
          </div>
          <div className="instagram">
            <img src="images/instagram.png" alt="" />
          </div>
        </section>
      </section>

    </div>
  )
}

export default Login
