import React from 'react'

import Header from '../../Components/Header'
import OrderStatus from '../../Components/OrderStatus'

import { useHistory } from 'react-router-dom'

import { useSelector } from 'react-redux'

import './index.css'

const Cart = () => {

  const history = useHistory()
  const articles = useSelector( state => state.cart.articles )

  return (
    <div>
      <Header />
      <OrderStatus status="" />

      <div className="linea-separador">
      </div>
      <section className="contenedor-articulos-resumen-pedido">
        <div className="contenedor-articulos">
          <div className="titulo-articulos">
            <h3>Artículos</h3>
          </div>
          { articles.map( article => (
            <div>
              <div className="campo-articulo">
                <div className="img-articulo">
                  <img src={article.photos?.[0]?.source} alt="" />
                </div>
                <div className="texto-descripcion">
                  <div className="nombre-producto">
                    <h4>{article.name}</h4>
                    <form action="" method="post" target="_blank">
                      <input type="number" className="cantidad" name="cantidad" />
                    </form>
                    <div className="precio">
                      <h4>${article.price}</h4>
                    </div>
                  </div>
                  <h5>Detalles del Producto</h5>
                  <p>
                    {article.description}
                  </p>
                </div>
              </div>
              <div className="eliminar-articulo">
                <input
                  type="reset"
                  className="sin-estilo"
                  value="Eliminar Artículo"/>
              </div>

            </div>
          )) }

          <div onClick={ () => history.push("/envio")} className="boton3">
            Continuar
          </div>
        </div>
        <div className="contenedor-resumen-pedido">
          <div className="resumen-titulo">
            <h2>Resumen del Pedido</h2>
          </div>
          <div className="listado-productos">
            <div className="titulo-productos">
              <h3>Productos</h3>
            </div>
            <div className="cantidad-productos">
              <h4>Nombre del Producto</h4>
              <div className="precio-producto">
                <h4>$1.488.000</h4>
              </div>
            </div>
            <div className="linea">
            </div>
            <div className="subtotal">
              <h4>Subtotal</h4>
              <div className="precio-subtotal">
                <h4>$1.488.000</h4>
              </div>
            </div>
            <div className="descuento">
              <h4>Descuento</h4>
              <div className="precio-descuento">
                <h4>$0</h4>
              </div>
            </div>
            <div className="linea">
            </div>
            <div className="total-producto">
              <h4>Total de los productos</h4>
              <div className="precio-producto">
                <h4>$1.488.000</h4>
              </div>
            </div>
            <div className="linea">
            </div>
            <div className="gastos-envio">
              <h4>Gastos de Envío</h4>
              <div className="precio-envio">
                <h4>$20.000</h4>
              </div>
            </div>
            <div className="linea">
            </div>
            <div className="total">
              <h3>Total</h3>
              <div className="precio-total">
                <h3>$1.508.000</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Cart
