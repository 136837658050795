import { createActions } from 'redux-actions'

import { getMonthProducts } from '../../Services/products'

const ProductsActions = createActions({
  MONTH_PRODUCTS_REQUEST: () => {},
  MONTH_PRODUCTS_RESPONSE: response => ({ response }),
})

ProductsActions.loadMonthProducts = () => {
  return async dispatch => {

    try {

      dispatch( ProductsActions.monthProductsRequest() )

      const monthProductsResponse = await getMonthProducts()

      console.log('monthProductsResponse: ', monthProductsResponse)

      if (!monthProductsResponse.ok)
        return dispatch( ProductsActions.monthProductsResponse( { error: monthProductsResponse.data.error } ) )

      dispatch(
        ProductsActions.monthProductsResponse({ products: monthProductsResponse.data.docs })
      )


    } catch (err) {
      dispatch( ProductsActions.monthProductsResponse({ error: err }) )
    }

  }
}

export default ProductsActions
