import { createActions } from 'redux-actions'

import {
  me,
} from '../../Services/account'

import {
  setAuthorizationToken,
  restoreAuthorizationToken,
  removeAuthorizationToken,
} from '../../Services/auth'

const AuthActions = createActions({
  SET_USER: user => ({ user }),
  VERIFY_AUTH: () => {},
  RESTORED_AUTH: () => {},
  NO_AUTH: () => {},
  ACCESS_TOKEN_SAVED: () => {},
})

AuthActions.restoreAuth = () => {
  return async dispatch => {
    dispatch( AuthActions.verifyAuth() )
    await restoreAuthorizationToken()
    const meResponse = await me()
    console.log('meResponse', meResponse)
    if (!meResponse.ok) return dispatch( AuthActions.noAuth() )
    dispatch(AuthActions.setUser( meResponse.data.data ))
    dispatch( AuthActions.restoredAuth() )
  }
}

AuthActions.removeAuth = () => {
  return dispatch => {
    removeAuthorizationToken()
    dispatch( AuthActions.noAuth() )
    dispatch( { type: 'RESET_ACCOUNT_STORE' } )
  }
}

AuthActions.setAccessToken = token => {
  return async dispatch => {
    await setAuthorizationToken(token)
    dispatch( AuthActions.accessTokenSaved() )
  }
}

export default AuthActions
