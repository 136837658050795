import React from 'react'

import { NavLink } from 'react-router-dom'

import './index.css'

const SearchBar = () => {
  return (
    <section className="browser">
      <div className="logo-negro">
        <img src="images/itto-logo-negro-letra.png" alt="" />
      </div>
      <div className="buscador-barra">
        <p className="buscar-productos">Buscar Productos
        </p>
      </div>
      <div className="contenedor-lupa">
        <img className="buscar"src="images/buscar.png" alt="" />
      </div>
      <div className="carrito-compras">
        <NavLink to="/carritto">
          <img src="images/carrito-compras-logo.png" alt="" />
          Carrito itto
        </NavLink>
      </div>
    </section>
  )
}

export default SearchBar
