import React, { useEffect } from 'react'

import Header from '../../Components/Header'
import SearchBar from '../../Components/SearchBar'
import Menu from '../../Components/Menu'
import Newsletter from '../../Components/Newsletter'
import Footer from '../../Components/Footer'
import Product from '../../Components/Product'

import { useDispatch, useSelector } from 'react-redux'
import ProductsActions from '../../Redux/products/actions'
import CartActions from '../../Redux/cart/actions'

const MonthProducts = () => {

  const dispatch = useDispatch()
  const products = useSelector( state => state.products.month )

  useEffect( () => {
    dispatch(ProductsActions.loadMonthProducts() )
  }, [dispatch])


  return (
    <div>

      <Header />
      <SearchBar />
      <Menu selected="productos-del-mes" />

      <div>
        { products.map( product => (
          <Product
            key={product._id}
            onSelect={ () => {
              dispatch( CartActions.addArticle(product) )
            }}
            name={product.name}
            price={product.price}
            image={product.photos?.[0]?.source || ""} />
        ) ) }
      </div>

      <Newsletter />
      <Footer />

    </div>
  )
}

export default MonthProducts
