import React, { useEffect, useRef } from 'react'

import AccountHeader from '../../../Components/Header/Account'

import { Formik } from 'formik'

import { useDispatch, useSelector } from 'react-redux'

import AccountActions from '../../../Redux/account/actions'

import './index.css'

const Login = ({ history }) => {

  const dispatch = useDispatch()
  const {
    loginLoading,
    loggedIn,
  } = useSelector( state => state.account )

  const formRef = useRef(null)

  useEffect( () => {
    formRef.current.setSubmitting(loginLoading)
  }, [formRef, loginLoading])

  useEffect( () => {
    if (loggedIn) {
      history.replace('/')
    }
  }, [history, loggedIn])


  const onSubmit = (values) => {
    dispatch( AccountActions.login(values.email, values.password) )
  }

  return (
    <div>
      <AccountHeader title="Ingresar"/>

      <section className="contenedor-ingresa">
        <section className="register-logo">
          <img src="images/register-2.png" alt="" />
          <h4>Ingresa</h4>
        </section>
        <Formik
          innerRef={formRef}
          onSubmit={onSubmit}
          initialValues={{ email: "", password: "" }}>
          { ({
            handleSubmit,
            values,
            handleChange,
            isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <section className="contenedor-campo-correo">
                <div className="email">
                  <input
                    placeholder="Email"
                    type="email"
                    name="email"
                    onChange={handleChange}
                    value={values.email} />
                </div>
                <div className="Contrasena">
                  <input
                    placeholder="Contraseña"
                    type="password"
                    name="password"
                    onChange={handleChange}
                    value={values.password} />
                </div>
              </section>
              <section className="continuar-boton">
                <button type="submit" disabled={isSubmitting}>
                  { isSubmitting ? "Cargando" : "Continuar" }
                </button>
              </section>
            </form>
          )}
        </Formik>
        <div className="ingresa-con">
          <h4>Ingresa Con</h4>
        </div>
        <section className="contenedor-redes-ingreso">
          <div className="facebook">
            <img src="images/facebook.png" alt="" />
          </div>
          <div className="google">
            <img src="images/google.png" alt="" />
          </div>
          <div className="twitter">
            <img src="images/twitter.png" alt="" />
          </div>
          <div className="instagram">
            <img src="images/instagram.png" alt="" />
          </div>
        </section>
      </section>

    </div>
  )
}

export default Login
