import Immutable from 'seamless-immutable'

export default Immutable({
  loginLoading: false,
  loginError: null,
  loggedIn: false,

  signUpLoading: false,
  signUpError: null,
  signedUp: false,

  forgotPasswordSent: false,
  forgotPasswordLoading: false,
  forgotPasswordError: null,

  changePasswordSent: false,
  changePasswordLoading: false,
  changePasswordError: null,

  editProfileSent: false,
  editProfileLoading: false,
  editProfileError: null,
})
