import React from 'react'

import './index.css'

const Newsletter = () => {
  return (
    <section className="contenedor-suscripcion-noticias">
      <div className="itto-send">
        <img src="images/itto-send.png" alt="" />
      </div>
      <div className="textos-noticias">
        <h3>Suscríbete Para Noticias y Descuentos</h3>
        <h4>y recibe un cupon del 10% en tu primera compra.</h4>
      </div>

        <div className="suscribir-barra">
          <p className="texto-suscribir">Suscribete para obtener descuentos (Email).
          </p>
        </div>
        <div className="contenedor-suscribirse">
          <h3>Suscribirme</h3>
        </div>
    </section>
  )
}

export default Newsletter
