import React, { Fragment } from 'react'

import { NavLink } from 'react-router-dom'

import './index.css'

const Footer = () => {
  return (
    <Fragment>
      <footer>
        <section className="logo-redondo">
          <a href="#inicio">
            <img src="images/itto-logo-redondo.png" alt="" />
          </a>
        </section>
        <section className="texto-direccion">
          <h3>37 Weimann Circle Suite 324</h3>
          <h3>Bogotá, Colombia</h3>
          <h4>contactanos@itto.com</h4>
        </section>
        <section className="listas-categorias">
          <h2>Categorías</h2>
          <NavLink to="/"> Computadores / Portátiles </NavLink>
          <NavLink to="/"> Videojuegos / Consolas </NavLink>
          <NavLink to="/"> Smartphones / Tablets </NavLink>
          <NavLink to="/"> Televisores </NavLink>
          <NavLink to="/"> Audio </NavLink>
          <NavLink to="/"> Automóviles / Repuestos </NavLink>
          <NavLink to="/"> Otros </NavLink>
        </section>
        <section className="listas-cliente">
          <h2>Clientes</h2>
          <NavLink to="/"> Mi Cuenta </NavLink>
          <NavLink to="/"> Soporte de Producto </NavLink>
          <NavLink to="/"> Cambios </NavLink>
        </section>
      </footer>
      <section className="powered">
        <h5>Copyright © 2020 / Todos los Derechos Reservados itto.com</h5>
        <a href="https://www.imperia.solutions" target="_blank" rel="noreferrer">
          <h4>Powered by IMPERIA</h4>
        </a>
      </section>
    </Fragment>
  )
}

export default Footer
