import Immutable from 'seamless-immutable'

const INITIAL_STATE = Immutable({
  monthProductsLoading: false,
  monthProductsError: null,

  month: [],

})

export default INITIAL_STATE
