import AUTH_STATE from './'
import AuthActions from './actions'

export function reducer(state = AUTH_STATE, action) {

  switch (action.type) {
    case AuthActions.loginRequest().type:
      return state.merge({
        loginLoading: true,
        loginError: null,
        loggedIn: false,
      })
    case AuthActions.loginSuccess().type:
      return state.merge({
        loginLoading: false,
        loginError: null,
        loggedIn: true,
      })
    case AuthActions.loginFailure().type:
      return state.merge({
        loginLoading: false,
        loginError: action.payload.error,
        loggedIn: false,
      })

    case AuthActions.signupRequest().type:
      return state.merge({
        signUpLoading: true,
        signUpError: null,
        signedUp: false,
      })
    case AuthActions.signupSuccess().type:
      return state.merge({
        signUpLoading: false,
        signUpError: null,
        signedUp: true,
      })
    case AuthActions.signupFailure().type:
      return state.merge({
        signUpLoading: false,
        signUpError: action.payload.error,
        signedUp: false,
      })

    case AuthActions.forgotPasswordRequest().type:
      return state.merge({
        forgotPasswordSent: false,
        forgotPasswordLoading: true,
        forgotPasswordError: null,
      })
    case AuthActions.forgotPasswordSuccess().type:
      return state.merge({
        forgotPasswordSent: true,
        forgotPasswordLoading: false,
        forgotPasswordError: null,
      })
    case AuthActions.forgotPasswordFailure().type:
      return state.merge({
        forgotPasswordSent: false,
        forgotPasswordLoading: false,
        forgotPasswordError: action.payload.error,
      })
    case AuthActions.forgotPasswordReset():
      return state.merge({
        forgotPasswordSent: false,
        forgotPasswordLoading: false,
        forgotPasswordError: null,
      })


    case AuthActions.changePasswordRequest().type:
      return state.merge({
        changePasswordSent: false,
        changePasswordLoading: true,
        changePasswordError: null,
      })
    case AuthActions.changePasswordSuccess().type:
      return state.merge({
        changePasswordSent: true,
        changePasswordLoading: false,
        changePasswordError: null,
      })
    case AuthActions.changePasswordFailure().type:
      return state.merge({
        changePasswordSent: false,
        changePasswordLoading: false,
        changePasswordError: action.payload.error,
      })

    case AuthActions.changePasswordReset().type:
      return state.merge({
        changePasswordSent: false,
        changePasswordLoading: false,
        changePasswordError: null,
      })


    case AuthActions.editProfileRequest().type:
      return state.merge({
        editProfileSent: false,
        editProfileLoading: true,
        editProfileError: null,
      })
    case AuthActions.editProfileSuccess().type:
      return state.merge({
        editProfileSent: true,
        editProfileLoading: false,
        editProfileError: null,
      })
    case AuthActions.editProfileFailure().type:
      return state.merge({
        editProfileSent: false,
        editProfileLoading: false,
        editProfileError: action.payload.error,
      })

    case AuthActions.editProfileReset().type:
      return state.merge({
        editProfileSent: false,
        editProfileLoading: false,
        editProfileError: null,
      })

    default:
      return state
  }
}
